import React from "react";
import { Container, Divider, Header, Table } from "semantic-ui-react";
import MiniBox from "../common/MiniBox";
import AutoAccordion from "../common/AutoAccordion";

const BehandlingAfPersonoplysninger = props => (
    <div>
        <Container text>
            <Header as="h2" textAlign="center">Behandling af person&shy;oplysninger</Header>
            <Divider hidden />

            <p>Letpension er pensions- og forsikringsformidler, som formidler pensions- og forsikringsløsninger for PFA Pension via en række pengeinstitutter med filialer i hele Danmark.
                Har du produkter via Letpension, er du derfor kunde i PFA Pension, og det er PFA Pension, der har ansvaret for at beskytte og behandle dine personoplysninger i forhold til dine forsikringer og pensioner.
                Letpension er databehandler for PFA Pension. Her kan du læse om, hvordan PFA Pension behandler dine personoplysninger i forhold til dine forsikrings- og pensionsaftaler.</p>

            <Header as="h4">PFA PENSION ER DEN DATAANSVARLIGE</Header>
            <p>Den juridiske enhed, som er ansvarlig for behandlingen af dine personoplysninger  ved oprettelse, administration af dine pensions- og forsikringsordninger og skadesbehandling er:</p>
            <p><strong>PFA Pension, Forsikringsaktieselskab</strong><br />
                Sundkrogsgade 4<br />
                2100 København Ø<br />
                CVR-nr. 13 59 43 76</p>
            <p>Du kan kontakte dit pengeinstitut og få oplyst, hvordan pengeinstituttet behandler dine personoplysninger i forbindelse med tilbudsgivning, rådgivning og indgåelse af forsikrings- og pensionsaftale i PFA Pension.</p>

            <Header as="h4">1. FORMÅL MED BEHANDLING AF PERSONOPLYSNINGER</Header>
            <p>PFA Pension behandler dine personoplysninger til de forskellige formål, som er beskrevet nedenfor. Under hvert formål kan du se, hvilke kategorier af
                personoplysninger PFA Pension behandler, hvorfra PFA Pension indsamler personoplysningerne, hvorfor de har lov til at behandle dine personoplysninger,
                og hvem PFA Pension kan videregive personoplysningerne til. Du får hele teksten frem ved at klikke på det/de formål, du ønsker at læse om. </p>

            <AutoAccordion activeIndex={-1}>

                <AutoAccordion.Item title="Oprettelse, individuel tilpasning og ændring af ordninger">
                    <p>Som kunde via Letpension har du dine forsikringer og pensioner  i PFA Pension.</p>
                    <p>Almindeligvis sker oprettelsen ved, at PFA Pension modtager en Letpensionsaftale fra dit pengeinstitut. Ved oprettelsen af din ordning og i forbindelse med senere individuelle tilpasninger eller
                        ændringer behandler PFA Pension de nødvendige oplysninger om dig, som de skal bruge til at oprette og ændre din ordning via Letpension hos PFA Pension.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
                        <p>Almindelige kategorier af personoplysninger:<br />
                            Navn, CPR-nummer,  legitimationsoplysninger , oplysninger om dit engagement i PFA Pension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger),
                            oplysninger om sygeperiode, civilstand (herunder eventuelt separations- og/eller skilsmissebevilling eller –dom), oplysninger om nærtstående (ægtefælle/samlever/børn) og i visse tilfælde oplysninger om nære samarbejdspartnere.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger):<br />
                            <i>Ved oprettelse, tilpasninger og ændringer:</i><br />
                            Ved oprettelse og individuelle tilpasninger, fx et ønske om forhøjelse af dækningers sum, kan det være nødvendigt for PFA Pension at behandle helbredsoplysninger om dig, evt. fra en anden ordning i PFA Pension.
                            PFA Pension kan også i visse tilfælde behandle oplysninger om dit politiske engagement i forbindelse med opfyldelse af hvidvasklovgivningen.
                        </p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension personoplysninger om dig fra?</strong></p>
                        <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra:</p>
                        <ul>
                            <li>CPR-registeret</li>
                            <li>Pengeinstitut</li>
                            <li>Tidligere og nuværende pensions- og forsikringsselskab</li>
                            <li>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter, psykologer m. fl.</li>
                            <li>Videncenter for Helbred og Forsikring</li>
                            <li>Internationale informationsudbydere og offentlige tilgængelige kilder </li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul className="list-icons">
                            <li>Indsamling og anvendelse af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan opfylde aftalen/pensions- og
                                forsikringsordningen, de har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b).
                            </li>
                            <li>Dine almindelige personoplysninger og følsomme personoplysninger indsamles, anvendes og videregives på baggrund af et samtykke fra dig (databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a,
                                jf. art. 6, stk.1, litra a, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).
                            </li>
                            <li>Behandling af følsomme personoplysninger kan være nødvendige for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesforordningen art. 9, stk. 2, litra f, jf. art. 6, stk. 1, litra b og litra f,
                                og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).
                            </li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver (lov om finansiel virksomhed § 43b,
                                jf. databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven § 11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b.
                            </li>
                            <li>Videregivelse af CPR-nummer kan være nødvendigt, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens
                                art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 1).
                            </li>
                            <li>CPR-nummer videregives på baggrund af et samtykke fra dig (databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a,  jf. art. 6, stk.1, litra a, og lov om
                                finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 2).
                            </li>
                            <li>Indsamling, anvendelse og videregivelse af CPR-nummer samt de almindelige- og følsomme personoplysninger er nødvendig, da PFA Pensioner retlig forpligtet til efter hvidvaskloven at legitimere og risikovurdere kunder
                                (herunder vurdere om du er politisk eksponeret eller nærtstående til en politisk eksponeret person) samt underrette Hvidvasksekretariatet om mistanke med henblik på at forebygge og bekæmpe hvidvask og terrorfinansiering
                                (databeskyttelsesloven § 11, stk. 2, nr. 1 samt databeskyttelsesforordningen art. 6, stk. 1, litra c).
                            </li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
                        <ul>
                            <li>Videncenter for Helbred og Forsikring, fx ved afslag på oprettelse</li>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser</li>
                            <li>Datacentraler</li>
                            <li>Dit pengeinstitut</li>
                            <li>Letpension</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Administration af ordninger">
                    <p>PFA Pension behandler dine personoplysninger, når de administrerer din ordning hos PFA Pension. Det kan fx være:</p>
                    <ul>
                        <li>når PFA Pension kommunikerer med dig, eller du gør brug af personaliserede digitale løsninger.</li>
                        <li>når PFA Pension administrerer indbetalinger og udbetalinger på din pensionsordning.</li>
                        <li>når PFA Pension sender dig oplysninger om ændringer i din pensionsoversigt, dine vilkår og anden administrativ information.</li>
                    </ul>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
                        <p>Almindelige kategorier af personoplysninger:<br />
                            Navn, kontaktoplysninger, civilstand, CPR-nummer, køn, kunde- eller policenummer, legitimationsoplysninger , Letsikringsnummer, oplysninger om dit engagement i PFA Pension (fx din indbetaling, risikoprofil, forsikringer og
                            eventuelle andre produkter), størrelse på pensionsopsparing, betalingsoplysninger, oplysninger om folkepension, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), kommunale oplysninger, oplysninger
                            om formueforhold, oplysninger om eventuel forsvinden, og personoplysninger om nærtstående (fx ægtefælle/samlever/børn) og visse tilfælde oplysninger om nære samarbejdspartnere.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger):<br />
                            Helbredsoplysninger. PFA Pension kan også i visse tilfælde behandle oplysninger om dit politiske engagement i forbindelse med opfyldelse af hvidvasklovgivningen.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension personoplysninger om dig fra?</strong></p>
                        <p>I administrationstilfælde bruger PFA Pension oplysninger, som de har i deres system eller i Letpensions system. Herudover vil vi ofte få
                            supplerende personoplysninger fra dig. Når du giver os dine oplysninger via Letpensions system, sker det via dit NemID-login eller MitID-login, hvorved
                            dataoverførslen sker via en sikker forbindelse til Letpension.</p>
                        <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra:</p>
                        <ul>
                            <li>Dit pengeinstitut</li>
                            <li>Tidligere pensions- og forsikringsselskab, fx hvis din pension ønskes overført fra et andet selskab til PFA Pension.</li>
                            <li>Offentlige myndigheder.</li>
                            <li>CPR-registeret ved adresseændring eller ændring af civilstand.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan opfylde
                                aftalen/pensions- og forsikringsordningen, PFA Pension har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b og lov om finansiel
                                virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).
                            </li>
                            <li>Behandling af følsomme personoplysninger er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesforordningen art. 9, stk. 2, litra f, jf. art. 6, stk. 1, litra b og litra f, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).
                            </li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver (Lov om finansiel virksomhed § 43b, jfr. Databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven §11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b.
                            </li>
                            <li>Videregivelse af CPR-nummer er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 1).
                            </li>
                            <li>Indsamling, anvendelse og videregivelse af CPR-nummer, de almindelige- og følsomme personoplysninger er nødvendig, da PFA Pension er retligt forpligtet til efter hvidvaskloven at legitimere og risikovurdere kunder samt underrette Hvidvasksekretariatet  om mistanke med henblik på at forebygge og bekæmpe hvidvask og terrorfinansiering. PFA Pension indhenter personoplysninger om dig i forbindelse med kundekendskabsprocedurer og som følge af undersøgelse, notering og overvågning af pensionsordningen (databeskyttelsesloven § 11, stk. 2, nr. 1 samt databeskyttelsesforordningen art. 9, stk. 2, litra g og databeskyttelsesforordningen art. 6, stk. 1, litra c).
                            </li>
                            <li>Indsamling, anvendelse og videregivelse af dit CPR-nummer samt de almindelige personoplysninger, er nødvendig, da PFA Pension er retligt forpligtet til at indberette til SKAT efter skattekontrolloven. Herudover har PFA Pension pligt til at tilbageholde skat ved udbetaling af pensionsopsparing til dig. I den forbindelse videregiver PFA Pension dine personoplysninger efter pensionsbeskatningsloven. CPR-nummer videregives med henblik på identifikation af kunden ved indberetning af økonomiske oplysninger og udbetaling af skat (databeskyttelsesloven § 11, stk. 2, nr. 1 samt databeskyttelsesforordningen art. 6, stk. 1, litra c).
                            </li>
                            <li>Videregivelse af dit CPR-nummer og almindelige personoplysninger til banker og andre pengeinstitutter er nødvendig for, at PFA Pension kan opfylde  aftalen/pensions- og forsikringsordningen, de har med dig (lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesforordningen art. 6, stk. 1, litra b samt databeskyttelsesloven § 11, stk. 2, nr. 3).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
                        <ul>
                            <li>Dit pengeinstitut, som assisterer dig vedrørende forhold om din pensionsindbetaling</li>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser.</li>
                            <li>Pengeinstitutter ved flytning af depoter</li>
                            <li>Offentlige myndigheder fx SKAT i forbindelse med lovpligtige indberetninger.</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Skadesbehandling og udbetaling af forsikring">
                    <p>PFA Pension behandler dine personoplysninger, når de vurderer, om de kan tilbyde behandling eller udbetale forsikring i forbindelse med en skade eller i forbindelse med forebyggelse af en skade. PFA Pension behandler også dine personoplysninger til at vurdere risikoen for svindel i din sag og på baggrund heraf eventuelt foretage yderligere undersøgelser. PFA Pension følger brancheforeningen Forsikring & Pensions kodeks om forsikringssvindel.</p>
                    <p>PFA Pension anvender, analyserer og sammenstiller automatisk de oplysninger, som de indsamler, for at kunne tilbyde dig målrettet og relevant skadesbehandling (under det man juridisk kalder ”profilering”).</p>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Navn, kontaktoplysninger, CPR-nummer, policenummer, begunstigelse, oplysninger om praktiserende læge, oplysninger om behandlingssted, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), betalingsoplysninger, indtægts- og formueforhold og oplysninger om offentlige ydelser, kontaktpunkter med PFA Pension (herunder mailkorrespondance), risikoscore, hvis du har anmeldt en skade samt for selvstændige tillige regnskaber for virksomheden, fritidsaktiviteter, familierelationer (ægtefælle/samlever/børn), civilstand og oplysninger om sociale problemer (der ikke er relateret til helbred). </p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger og i særlige tilfælde oplysninger om religiøs overbevisning, oplysninger om fagforeningsmæssige tilhørsforhold eller oplysninger om seksuelle forhold. </p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension personoplysninger om dig fra?</strong></p>
                        <p>Du er den primære kilde til personoplysninger om dig selv, og PFA Pension vil normalt indhente dine personoplysninger direkte fra dig. Det vil typisk være på systemer, hvor du skal logge ind med dit NemID/MitID, som sikrer, at dataoverførslen sker via en sikker forbindelse eller det kan være ved telefonisk kontakt.</p>
                        <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra: </p>
                        <ul>
                            <li>Tidligere pensions- og forsikringsselskab (hvis din pension eller forsikring er overført fra et andet selskab).</li>
                            <li>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter m.fl.</li>
                            <li>Offentlige myndigheder.</li>
                            <li>Offentlige og private registre.</li>
                            <li>Sociale medier.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig for at opfylde aftalen/pensions- og forsikringsordningen, som PFA Pension har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).</li>
                            <li>Indsamling og anvendelse af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan forfølge en legitim interesse, nemlig til forebyggelse af misbrug af forsikringen (databeskyttelsesforordningen art. 6, stk. 1, litra f).</li>
                            <li>Indsamling af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan forfølge en legitim interesse, nemlig til forebyggelse af sygefravær (databeskyttelsesforordningen art. 6, stk. 1, litra f).</li>
                            <li>Indsamling, anvendelse og videregivelse af helbredsoplysninger er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesforordningens art. 9, stk. 2, litra f, jf. art. 6, stk. 1, litra b og litra f, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).</li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver (lov om finansiel virksomhed § 43b, jfr. databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven §11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b.</li>
                            <li>Videregivelse af CPR-nummer er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 1).</li>
                            <li>Videregivelse af almindelige - og følsomme personoplysninger sker i andre tilfælde med dit samtykke (databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a, jf. art. 6, stk. 1, litra a og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3) eller på baggrund af lov, herunder kan PFA Pension videregive til Patienterstatningen på baggrund af lov om klage og erstatningsadgang inden for sundhedsvæsenet (databeskyttelsesforordningen art. 6, stk. 1, litra c, databeskyttelsesforordningen art. 9, stk. 2, litra g og lov om klage og erstatningsadgang inden for sundhedsvæsenet).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
                        <ul>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support, leverandørydelser og formidling af sundhedsydelser.</li>
                            <li>Videncenter for Helbred og Forsikring. PFA Pension videregiver i særlige tilfælde oplysninger for at kunne få en udtalelse med henblik på, at
                                PFA Pension kan træffe en afgørelse.
                            </li>
                            <li>Offentlige myndigheder, fx Patienterstatningen. PFA Pension videregiver oplysninger på baggrund af lov om klage og erstatningsadgang inden for
                                sundhedsvæsenet.
                            </li>
                            <li>Sygeforsikring ”danmark”. Hvis kunden er medlem af Sygeforsikring ”danmark”, videregiver PFA Pension personoplysninger hertil i forbindelse
                                med mulig refusion.
                            </li>
                            <li>Repræsentanter, såsom advokater eller fagforening.</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Når din ordning ophører">
                    <p>PFA Pension behandler dine personoplysninger, når din ordning ophører. Det kan være, hvis du vælger at opsige en aftale om pension eller
                        forsikring.</p>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig, som PFA Pension allerede har registreret. Disse personoplysninger vil være: </p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Navn, kontaktoplysninger, CPR-nummer, kunde- og policenummer/Letsikringsnummer.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig af hensyn til opfyldelse af aftalen pensions- og forsikringsordningen (databeskyttelsesforordningen art. 6, stk. 1, litra b og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3). </li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver (lov om finansiel virksomhed § 43b, jf. databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven §11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b).</li>
                            <li>Videregivelse af CPR-nummer er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3.</li>
                            <li>Anvendelse og videregivelse af dine følsomme personoplysninger er nødvendig for, at retskrav kan fastsættes, gøres gældende eller forsvares (databeskyttelsesforordningen art. 9, stk. 2, litra f, jf. art. 6, stk. 1, litra b og litra f, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).</li>
                            <li>Videregivelse af dine almindelige personoplysninger og dit CPR-nummer sker i andre tilfælde på baggrund af dit samtykke (databeskyttelsesloven § 11, stk. 2 samt databeskyttelsesforordningen art. 6, litra a, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>kategorier af modtagere</i>:</p>
                        <ul>
                            <li>Dit nye pensions- og forsikringsselskab.</li>
                            <li>Banker og andre pengeinstitutter ved overførsel af din pensionsordning efter dit ønske.</li>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser.</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Klagebehandling">
                    <p>Som kunde via Letpension er du kunde i PFA Pension. PFA Pension har ansvar for at administrere de ordninger, du har oprettet via Letpension.</p>
                    <p>PFA Pension har et ansvar for at sikre dig en fair behandling, herunder at du får de udbetalinger, du er berettiget til, hverken mere eller mindre.
                        Det kan en gang imellem give anledning til klager.</p>
                    <p>PFA Pension anvender fx dine personoplysninger til at behandle en eventuel klage fra dig over en afgørelse, der er truffet af PFA Pension. Det kan
                        også være, at du ikke er tilfreds med svaret i klagebehandlingen og vælger at klage over PFA Pension til Ankenævnet for Forsikring eller at anlægge
                        sag mod PFA Pension ved domstolene. I disse tilfælde vil PFA Pension også anvende dine personoplysninger.</p>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
                        <p>Almindelige kategorier af personoplysninger:<br />
                            Navn, kontaktoplysninger, CPR-nummer, kunde- eller policenummer/Letsikringsnummer, oplysninger om dit engagement i PFA Pension (fx din
                            indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), begunstigelse, skatteoplysninger, fritidsaktiviteter, formueforhold,
                            oplysninger om dit ansættelsesforhold (fx arbejdsgiver og, lønoplysninger), erhvervssituation, familierelationer (ægtefælle/samlever/børn),
                            civiltilstand og oplysninger om sociale problemer (der ikke er relateret til helbred).</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger og i særlige tilfælde oplysninger om religiøs overbevisning, oplysninger om fagforeningsmæssige tilhørsforhold eller oplysninger om seksuelle forhold. </p>
                        <p>Personoplysninger vedrørende straffedomme og lovovertrædelser:<br />
                            Oplysninger om strafbare forhold.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension personoplysninger om dig fra?</strong></p>
                        <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra: </p>
                        <ul>
                            <li>Pengeinstitut</li>
                            <li>Repræsentanter, såsom advokat</li>
                            <li>Tidligere eller andet pensions- og forsikringsselskab, fx hvis din pension eller forsikring er overført fra et andet selskab.</li>
                            <li>Offentlige myndigheder (fx kommunale dokumenter ved invalidepensionssager).</li>
                            <li>Offentlige myndigheder i forbindelse med sager (fx ved Datatilsynet eller Finanstilsynet).</li>
                            <li>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter, psykologer m.fl.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>De almindelige personoplysninger indsamles, anvendes og videregives ud fra en interesseafvejning i forhold til, at retskrav skal kunne fastlægges, gøres gældende og forsvares af PFA Pension, hvis en kunde klager over en afgørelse truffet af PFA Pension (databeskyttelsesforordningen art. 6, stk. 1, litra f og  lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3). </li>
                            <li>Indsamling og anvendelse af almindelige og følsomme personoplysninger er nødvendig for at overholde en retlig forpligtelse (databeskyttelsesforordningen art. 6, stk. 1, litra c og art. 9, stk. 2, litra g, jf. art. 6, stk.1, litra c). PFA Pension har en retlig forpligtelse efter bekendtgørelse om den klageansvarlige og finansielle virksomheders håndtering af klager. </li>
                            <li>De følsomme personoplysninger indsamles, anvendes og videregives, hvis de er nødvendige for, at retskrav kan fastlægges, gøres gældende eller forsvares af PFA Pension (databeskyttelsesforordningen art. 9, stk. 2, litra f, jf. art. 6, stk. 1, litra b, og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3).</li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver eller rådgivning (lov om finansiel virksomhed § 43b, jf. databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven  § 11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b.</li>
                            <li>Videregivelse af CPR-nummer er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 1).</li>
                            <li>Indsamling, anvendelse og videregivelse af oplysninger om strafbare forhold  er nødvendig, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 8, stk. 5 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningen art. 9 stk. 2, litra f).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>kategorier af modtagere</i>:</p>
                        <ul>
                            <li>Repræsentanter, såsom advokat</li>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser.</li>
                            <li>Offentlige myndigheder i forbindelse med myndighedssager, fx Datatilsynet eller Finanstilsynet.</li>
                            <li>Pengeinstitut i forbindelse med udbetaling til dig eller afklaring af udbetaling.</li>
                            <li>Domstole og ankenævn.</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Analyse, statistik, innovative initiativer og profilering">
                    <p><strong>Analyse og statistik</strong></p>
                    <p>PFA Pension behandler dine personoplysninger, når PFA Pension udarbejder statistikker og analyser. Det kan være:</p>
                    <ul>
                        <li>Interne undersøgelser, analyser og lønsomhedsvurderinger</li>
                        <li>Solvensopgørelser og lovpligtige solvensrapporteringer</li>
                        <li>Aktuarberetning</li>
                        <li>Statistiske undersøgelser af væsentlig samfundsmæssig betydning vedrørende årsager til sygdom, sygdomsmønstre og sammenhæng til dødelighed.</li>
                    </ul>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med at PFA Pension udarbejder statistikker og analyser, behandler PFA Pension forskellige personoplysninger om dig.
                            Personoplysningerne vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Navn, kontaktoplysninger, CPR-nummer, køn, kunde- eller policenummer, begunstigelse, oplysninger om dit engagement i PFA Pension (fx din
                            indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger),
                            pensionsindbetaling, skatteoplysninger, formueforhold, depotstørrelse, familierelationer (ægtefælle/samlever/børn), oplysninger om dødsfald og
                            oplysninger om forsvinden.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger for at kunne drive forsvarlig forsikringsvirksomhed ud fra følgende
                            juridiske grundlag:</p>
                        <ul>
                            <li>Behandlingen af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse, som påhviler PFA Pension efter Solvens II forordningen, POG (IDD)-forordningen vedrørende krav til produkttilsyn og styring for forsikringsselskaber og forsikringsdistributører samt lov om finansiel virksomhed (databeskyttelsesforordningen art. 6, stk.1, litra c, og fsva. CPR-nummer, databeskyttelsesloven § 11, stk. 2, nr. 1 og nr. 3). Den retlige forpligtelse findes i lov om Danmarks Nationalbank (”statistiske oplysninger inden for dens kompetenceområde”) og i lov om finansiel virksomhed (”de oplysninger der er nødvendige for Finanstilsynets virksomhed”). </li>
                            <li>PFA Pension udarbejder statistikker, analyser og lovpligtige rapporteringer på en række områder, herunder solvensopgørelser, hensættelser, risikorapportering, bestandsrapporter, afløbsanalyser, simulering af forventede og aktuelle skadesforløb/risikoafdækning i forhold til de enkelte forsikringsprodukter, bl.a. produktrentabilitet, tarifering og skadesudvikling samt i forhold til forsikringsporteføljen som helhed. PFA Pension behandler følsomme personoplysninger, hvis behandlingen er nødvendig af hensyn til væsentlige samfundsinteresser efter Solvens II forordningen, lov om finansiel virksomhed samt ledelsesbekendtgørelsen (databeskyttelsesforordningen art. 9, stk. 2, litra g, jf. art. 6, stk. 1, litra c). Det følger blandt andet af ovenstående, at PFA Pension er pålagt at foretage beregningerne og analyserne.</li>
                            <li>PFA Pension udarbejder analyser af sammenkørte oplysninger om skader, sygdom, sygdomsmønstre og sammenhæng til dødelighed. Analyserne foretages  med det formål at mindske risikoen for sygdom og død samt at opnå generel viden om effekten af forebyggende indsatser til gavn for samfundet. Disse analyser foretages på baggrund af POG (IDD)-forordningen vedrørende krav til produkttilsyn og styring for forsikringsselskaber og forsikringsdistributører (databeskyttelsesforordningen art. 9, stk. 2, litra g, jf. art. 6, stk 1, litra c). </li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere</i>:</p>
                        <ul>
                            <li>Danmarks Statistik</li>
                            <li>Finanstilsynet</li>
                        </ul>
                    </MiniBox>
                    <p><strong>Innovative initiativer og profilering</strong></p>
                    <p>PFA Pension behandler personoplysninger om kunder fra forskellige kilder til forskningsformål i form af udvikling, træning og løbende monitorering af algoritmer, statistik samt innovative initiativer (’forskning’) med henblik på udvikling af støtteværktøjer, som kan udlede forhold om kunders tilsyneladende statistisk ensartede forhold. De udviklede støtteværktøjer indeholder ikke personoplysninger. Støtteværktøjerne indgår i PFA Pensions driftsmæssige aktiviteter, administration og lovopfyldelse og som beslutningsunderstøttelse for deres forretningsenheder. </p>
                    <p>Der er tale om følgende forskningsformål:</p>
                    <p><strong><u>Mistanke om forsikringssvindel</u></strong><br />
                        Støtteværktøjet kan anvendes til at beregne en risikoscore for mulig forsikringssvindel på kundeniveau.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med forskningen behandler PFA Pension forskellige personoplysninger. Det vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Kontaktoplysninger, kundenummer, køn, alder, policeoplysninger, dækningskriterier, antal virksomheder på kundens adresse, ejerandel, økonomiske oplysninger og arbejdsforhold.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>Der indsamles oftest ikke andre personoplysninger end dem, der allerede er indsamlet som led i kundeforholdet med dig. Hvis PFA Pension modtager nye oplysninger, vil det være:</p>
                        <ul>
                            <li>Adresseoplysninger fra dawa.aws.dk og aggregerede statistiske oplysninger fra Danmarks statistik.</li>
                            <li>Oplysninger fra CVR.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension behandler dine personoplysninger ud fra følgende juridiske grundlag:<br />
                            PFA Pension behandler personoplysninger til forskning af væsentlig samfundsmæssig betydning for at forebygge og opdage mulig forsikringssvindel med henblik på at sikre, at kundernes krav på erstatning er berettiget, og at kunder ikke skal betale højere præmier end nødvendigt pga. forsikringssvindel (databeskyttelsesforordningen art. 6, stk. 1, litra e og databeskyttelseslovens § 10, stk. 1).</p>
                    </MiniBox>
                    <p><strong><u>Mistanke om hvidvask og finansiering af terrorisme</u></strong><br />
                        Støtteværktøjet kan anvendes til at forebygge og opdage risiko for hvidvask og finansiering af terrorisme.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med forskningen behandler PFA Pension forskellige personoplysninger. Det vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Kunde- eller policenummer, køn, alder, civilstand, policeoplysninger, økonomiske oplysninger og arbejdsforhold samt oplysninger fra folkeregisteret.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>Der indsamles oftest ikke andre personoplysninger end dem, der allerede er indsamlet som led i kundeforholdet med dig. Hvis PFA Pension modtager nye oplysninger, vil det være:</p>
                        <ul>
                            <li>PEP (politisk eksponerede personer) - og sanktionslister.</li>
                            <li>Adresseoplysninger fra dawa.aws.dk og aggregerede statistiske oplysninger fra Danmarks statistik.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension behandler dine personoplysninger ud fra følgende juridiske grundlag:<br />
                            Behandling af personoplysninger til forskning er nødvendig af hensyn til at udføre en opgave i samfundets interesse, nemlig til at forebygge og opdage risiko for hvidvask og finansiering af terrorisme (databeskyttelsesforordningen art. 6, stk. 1, litra e).</p>
                    </MiniBox>

                    <p><strong><u>Kundefastholdelse</u></strong><br />
                        Støtteværktøjet indeholder generelle profiler for kunder og kundegrupper og kan anvendes til at indgå i PFA Pensions indsatser for at fastholde kunder (forudsigelse af churn). </p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med forskningen behandler PFA Pension forskellige personoplysninger. Det vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Kunde- eller policenummer, køn, policeoplysninger, økonomiske oplysninger, branche for ansættelse samt oplysninger fra folkeregisteret om bl.a. ændringer i adresse og civilstand. </p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>Der indsamles oftest ikke andre personoplysninger end dem, der allerede er indsamlet som led i kundeforholdet med dig. Hvis PFA Pension modtager nye oplysninger, vil det være:</p>
                        <ul>
                            <li>Adresseoplysninger fra dawa.aws.dk og aggregerede statistiske oplysninger fra Danmarks statistik.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension behandler dine personoplysninger ud fra følgende juridiske grundlag:<br />
                            PFA Pension behandler personoplysninger til forskning for, at PFA Pension kan forfølge en legitim interesse, nemlig til at indgå i deres indsatser for at fastholde kunder (databeskyttelsesforordningen art. 6, stk. 1, litra f).</p>
                    </MiniBox>
                    <p><strong><u>Forebyggelse af risiko for skader</u></strong><br />
                        Støtteværktøjet indeholder generelle profiler for kunder og kan hjælpe med at forudsige forsikredes risiko for langtidssygdom samt måle effekten af PFA Pensions målrettede sundhedstilbud.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med forskningen behandler PFA Pension forskellige personoplysninger. Det kan være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Kunde- eller policenummer, køn, civilstand, policeoplysninger, brug af forsikringer, økonomiske oplysninger og arbejdsforhold samt oplysninger fra folkeregisteret om bl.a. ændringer i navn, adresse og civilstand, kontaktpunkter med PFA (herunder brug af Mit PFA samt mailkorrespondance).</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>Der indsamles oftest ikke andre personoplysninger end dem, der allerede er indsamlet som led i kundeforholdet med dig. Hvis PFA Pension modtager nye oplysninger, vil det være:</p>
                        <ul>
                            <li>Adresseoplysninger fra dawa.aws.dk og aggregerede statistiske oplysninger fra Danmarks statistik.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension behandler dine personoplysninger ud fra følgende juridiske grundlag:<br />
                            PFA Pension behandler oplysninger til forskning for at opdage længerevarende sygdom og til en vis grad forebygge sådanne sygdomme samt måle effekten af PFA Pensions målrettede sundhedstilbud (databeskyttelsesforordningen art. 6, stk. 1, litra e) og databeskyttelseslovens § 10, stk. 1).</p>
                    </MiniBox>
                    <p><strong><u>Optimering af PFA Pensions interne forretningsprocesser</u></strong><br />
                        Støtteværktøjet kan anvendes til at optimere PFA Pensions interne forretningsprocesser ved besvarelse af korrespondance i PFA Pensions kontaktcentre, opfølgning på skader på relevante tidspunkter, sammenligning af ensartede sager samt scanning for uvedkommende fortrolige og følsomme personoplysninger i kunders anmodning om indsigt.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>I forbindelse med forskningen behandler PFA Pension forskellige personoplysninger. Det vil være:</p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Navn, kontaktoplysninger, kundenummer, alder, dækningskriterier, policeoplysninger, brug af forsikringer, økonomiske oplysninger og arbejdsforhold samt kontakter med PFA Pension (bl.a. besøg på Mit PFA,) samt svar på spørgeskemaer om tilfredshed og selvvurderet helbred).</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>Der indsamles oftest ikke andre personoplysninger end dem, der allerede er indsamlet som led i kundeforholdet med dig. Hvis PFA Pension modtager nye oplysninger, vil det være:</p>
                        <ul>
                            <li>Adresseoplysninger fra dawa.aws.dk og aggregerede statistiske oplysninger fra Danmarks statistik.</li>
                            <li>Oplysninger fra CVR.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension behandler dine personoplysninger ud fra følgende juridiske grundlag:<br />
                            Behandling af personoplysninger til forskning er nødvendig af hensyn til at udføre en opgave i samfundets interesse, nemlig til at hjælpe med at kontakte kunder på relevante tidspunkter og svare hurtigt og korrekt på kunders spørgsmål (databeskyttelsesforordningen art. 6, stk. 1, litra f og databeskyttelseslovens § 10, stk. 1).</p>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Overholdelse af lovmæssige forpligtelser">
                    <p>PFA Pension behandler dine personoplysninger ved lovpligtige indberetninger, og når de skal sikre, overholdelse af gældende lovgivning, fx om
                        hvidvask, persondata og finansiel virksomhed.</p>
                    <p>PFA Pension anvender, analyserer og sammenstiller automatisk de oplysninger, som de indsamler, for at kunne forebygge og opdage risiko for hvidvask og finansiering af terrorisme  (under det man juridisk kalder ”profilering”). </p>
                    <p>PFA Pension indsamler, anvender og videregiver dine oplysninger med henblik på at overholde databeskyttelsesforordningen, databeskyttelsesloven og anden relevant lovgivning, fx lov om finansiel virksomhed. Det kan fx være:</p>
                    <ul>
                        <li>Dokumentationspligt</li>
                        <li>Lovpligtige indberetninger til myndighederne</li>
                        <li>Overholdelse af principper for behandling af personoplysninger og det juridiske grundlag for behandlingen</li>
                        <li>Beskyttelse af personoplysninger ved iværksættelse og vedligeholdelse af tekniske og organisatoriske sikkerhedsforanstaltninger, fx for at
                            hindre uautoriseret adgang til PFA Pensions it-systemer
                        </li>
                        <li>Undersøgelse af mistanke eller viden om sikkerhedsbrud og derefter rapportering til kunder eller andre berørte og til Datatilsynet</li>
                        <li>Håndtering af forespørgsler og klager fra kunder og andre,</li>
                        <li>Håndtering af inspektioner og forespørgsler fra Datatilsynet og Finanstilsynet</li>
                        <li>Håndtering af tvister fx ankenævnssager og retssager</li>
                        <li>Statistik</li>
                    </ul>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Ydelser og udbetaling til ægtefæller, samlever og børn">
                    <p>Hvis du er ægtefælle/registreret partner, samlever, tidligere ægtefælle eller registreret partner, begunstiget eller nærmeste pårørende til en
                        pensions- eller forsikringskunde hos PFA Pension, anvender de dine personoplysninger til bl.a. administration af udbetaling til dig ved dødsfald
                        eller ved deling af pensionsordning ved fx skilsmisse.</p>

                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension anvender forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
                        <p>Almindelige kategorier af personoplysninger:<br />
                            Navn, kontaktoplysninger, CPR-nummer, legitimationsoplysninger (fx pas, hvis du ikke kan legitimeres via CPR-registeret), betalingsoplysninger,
                            familierelationer (ægtefælle/registreret partner/samlever/børn), lønoplysninger, vielsesdato og civilstand (herunder eventuelt separations-
                            og/eller skilsmissebevilling eller –dom).</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension personoplysninger om dig fra?</strong></p>
                        <p> Når PFA Pension ikke får personoplysningerne fra dig, kan vi få dem fra:</p>
                        <ul>
                            <li>Offentlige myndigheder (f.eks. SKAT, skifteretten)</li>
                            <li>Boet efter afdøde</li>
                            <li>Nets Denmark A/S (oplysninger om NEM-konto til brug for udbetaling)</li>
                            <li>Pengeinsitutter</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>Indsamling, anvendelse og videregivelse af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse
                                (databeskyttelsesforordningens art. 6, stk. 1, litra c). PFA Pension har en retlig forpligtelse efter skattekontrolloven til at tilbageholde
                                skat ved udbetaling af pensionsopsparing til dig. PFA Pension videregiver dine personoplysninger til SKAT efter pensionsbeskatningsloven.
                            </li>
                            <li>Indsamling, anvendelse og videregivelse af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse
                                (databeskyttelsesforordningen art. 6, stk. 1, litra c). PFA Pension er efter hvidvaskloven retligt forpligtet til at legitimere og risikovurdere
                                dig samt underrette Hvidvasksekretariatet (Statsadvokaten for Særlig Økonomisk og International Kriminalitet) om mistanke med henblik på at
                                forebygge og bekæmpe hvidvask og terrorfinansiering. PFA Pension har også en retlig forpligtelse efter forsikringsaftaleloven til at underrette
                                Skifteretten om begunstigelsesindsættelser.
                            </li>
                            <li>CPR-nummer indsamles og anvendes med henblik på nødvendig entydig identifikation i relation til eksisterende kundeforhold ved varetagelse af administrative opgaver (lov om finansiel virksomhed § 43b, jf. databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven § 11, stk. 2, nr. 1 og databeskyttelsesforordningen art. 6, stk. 1, litra b).</li>
                            <li>Videregivelse af CPR-nummer er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f og lov om finansiel virksomhed § 117, stk. 1, jf. forordningens art. 6, stk. 1, jf. stk. 2, jf. stk. 3, og databeskyttelsesloven § 11, stk. 2, nr. 1).</li>
                            <li>CPR-nummer videregives for at kunne identificere dig ved indberetning af økonomiske oplysninger (databeskyttelsesloven § 11, stk. 2, nr. 1 og lov finansiel lovgivning § 117, stk.1). </li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende kategorier af modtagere:</p>
                        <ul>
                            <li>Offentlige myndigheder, fx Skifteretten og SKAT i forbindelse med tilbageholdelse af boafgift og skat.</li>
                            <li>Repræsentanter for boet.</li>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser.</li>
                        </ul>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Videoovervågning i PFA Pensions reception">
                    <p>PFA Pensions reception er videoovervåget for at skabe tryghed, forebygge og opklare kriminalitet.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være: </p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Videooptagelser fra PFA Pensions reception.</p>
                        <p>Personoplysninger vedrørende lovovertrædelser: <br />
                            Eventuelle strafbare forhold begået i PFA Pensions reception.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra?</strong></p>
                        <p>PFA Pensions indsamler dine personoplysninger, når du befinder dig i PFA Pensions reception.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>De personoplysninger, som  PFA Pension har indsamlet via videoovervågningen, behandler og videregiver PFA Pension under hensyn til legitime interesser i at skabe tryghed, forebygge og opklare kriminalitet (databeskyttelsesforordningen art. 6, stk. 1, litra f).</li>
                            <li>De personoplysninger, som PFA Pension har indsamlet via videoovervågningen videregives til politiet i kriminalitetsopklarende øjemed (tv-overvågningsloven § 4 C).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende kategori af modtagere:</p>
                        <ul>
                            <li>PFA Pensions samarbejdspartnere, som assisterer PFA Pension med fx teknisk support og leverandørydelser.</li>
                            <li>Politiet hvis nødvendigt.</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Opbevaring</strong></p>
                        <p>Videooptagelserne opbevares i 30 dage, hvorefter de slettes.</p>
                    </MiniBox>
                </AutoAccordion.Item>

                <AutoAccordion.Item title="Optagelse af telefonsamtaler til uddannelse og læring">
                    <p>Når du ringer til PFA Pension, kan PFA Pension i nogle tilfælde bede  om dit samtykke til at optage samtalen til uddannelse og læring.</p>
                    <MiniBox>
                        <p><strong>Kategorier af personoplysninger</strong></p>
                        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være: </p>
                        <p>Almindelige kategorier af personoplysninger: <br />
                            Navn, kontaktoplysninger, CPR-nummer, kunde- eller policenummer, stilling, oplysninger om dit engagement i PFA Pension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), begunstigelse, skatteoplysninger, fritidsaktiviteter, formueforhold, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), erhvervssituation, familierelationer (ægtefælle/samlever/børn), civilstand, oplysninger om praktiserende læge, oplysninger om behandlingssted, oplysninger om sociale problemer (der ikke er relateret til helbred) og oplysninger om offentlige ydelser.</p>
                        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br />
                            Helbredsoplysninger og i særlige tilfælde oplysninger om fagforeningsforhold, religiøs overbevisning eller oplysninger om seksuelle forhold. </p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvor får PFA Pension oplysninger om dig fra? </strong></p>
                        <p>PFA Pension indsamler personoplysninger om dig, når du ringer til PFA Pension og har givet dit samtykke til, at samtalen må optages til uddannelse og læring.</p>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</strong></p>
                        <p>PFA Pension indsamler og  anvender dine personoplysninger ud fra følgende juridiske grundlag:</p>
                        <ul>
                            <li>De almindelige personoplysninger indsamles og anvendes ud fra et samtykke. (databeskyttelsesforordningen art. 6, stk. 1, litra a). </li>
                            <li>De følsomme personoplysninger indsamles og anvendes ud fra et samtykke (databeskyttelsesforordningen art. 9, stk. 2, litra a, jf. art. 6, stk.1, litra a). </li>
                            <li>Indsamling og anvendelse af CPR-nummer ud fra et samtykke (databeskyttelsesforordningen art. 87, jf. databeskyttelsesloven § 11, stk. 2, nr. 2 og databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a, jf. art. 6, stk. 1, litra a).</li>
                        </ul>
                    </MiniBox>
                    <MiniBox>
                        <p><strong>Videregivelse af dine personoplysninger</strong></p>
                        <p>PFA Pension kan videregive dine personoplysninger til følgende kategori af modtagere:</p>
                        <p>PFA Pensions samarbejdspartnere, som assisterer vores virksomhed med fx teknisk support og leverandørydelser.</p>
                    </MiniBox>
                </AutoAccordion.Item>

            </AutoAccordion>


            <Header as="h4">2. OVERFØRSEL AF PERSONOPLYSNINGER TIL LANDE UDEN FOR EU/EØS</Header>
            <p>PFA Pension bruger leverandører og samarbejdspartnere, som varetager opgaver for PFA Pension. Det betyder, at dine personoplysninger behandles af leverandører og samarbejdspartnere (databehandlere), når det er nødvendigt for,
                at databehandleren kan udføre den pågældende opgave for PFA Pension. </p>

            <p>PFA Pension beskytter dine personoplysninger. Ved overførsel af personoplysninger til databehandlere i tredjelande, fx ved hosting- eller supportydelser fra en af PFA Pensions databehandlere,
                sikrer PFA Pension et overførselsgrundlag og et tilstrækkeligt beskyttelsesniveau. </p>

            <p>Du kan få nærmere oplysninger om PFA Pensions overførsel af dine personoplysninger til tredjelande ved at kontakte PFA Pensions databeskyttelsesrådgiver.
                Du kan fra PFA Pensions databeskyttelsesrådgiver enten få udleveret en kopi af EU Kommissionens standardkontrakt, et andet juridisk grundlag for overførslen af personoplysninger, eller få oplysning om, hvor overførselsgrundlaget er blevet gjort tilgængeligt.</p>

            <Header as="h4">3. OPBEVARING AF DINE PERSONOPLYSNINGER</Header>
            <p>PFA Pension opbevarer dine personoplysninger, indtil kundeforholdet er ophørt, og forældelsesfristerne i forældelsesloven er udløbet. Opbevaringen af
                personoplysningerne sker tillige med respekt for PFA Pensions forpligtelser til at opbevare personoplysninger i henhold til regnskabs- og
                bogføringslovgivningen. Der kan forekomme undtagelser, men PFA Pension opbevarer dine personoplysninger som angivet nedenfor. Herefter vil dine
                personoplysninger blive slettet.</p>

            <Table color="teal" inverted size="small" striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Hovedregler</Table.HeaderCell>
                        <Table.HeaderCell>Opbevaringsperiode</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Personoplysninger der er en del af dit kundeforhold med PFA Pension (”den almindelige forældelsesfrist”)</Table.Cell>
                        <Table.Cell>10 år efter kundeforholdets ophør*</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Forsikringstilbud, som du ikke accepterer inden acceptfristen</Table.Cell>
                        <Table.Cell>6 mdr. efter acceptfristens udløb</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Hvis du trækker din ansøgning om forsikring tilbage efter indsendelse af helbredsoplysninger til PFA Pension</Table.Cell>
                        <Table.Cell>Personoplysninger slettes umiddelbart efter tilbagetrækning af ansøgningen</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Personoplysninger, som indhentes og behandles om dig til overholdelse af hvidvaskloven</Table.Cell>
                        <Table.Cell>5 år efter kundeforholdets ophør</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Optagelse af telefonsamtaler til uddannelse og læring</Table.Cell>
                        <Table.Cell>3 mdr. efter tidspunktet for optagelse</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <MiniBox size="tiny" secondary>
                <p>* PFA Pension sender et brev til dig, når du ikke længere har pensionsordninger, forsikringer eller andre aftaler om ydelser i PFA Pension. Er dit
                    kundeforhold ophørt før tid, fx som følge af tilbagekøb eller overførsel, opbevarer PFA Pension brevet som dokumentation for kundeophøret i 10 år
                    efter det seneste tidspunkt, hvor du ville kunne have krævet ydelser, hvis de ophørte ordninger fortsat var i PFA Pension. Der kan være
                    karensperioder, der ikke er taget højde for i ovennævnte frist. I tillæg til den almindelige forældelsesfrist på 10 år anvender PFA Pension derfor en
                    periodemæssig sikkerhedsmargin på et år, før oplysningerne slettes. Personoplysninger, der behandles til statistiske formål, opbevares i 30 år efter
                    kundeforholdets ophør.</p>
                <p>Hvis PFA Pension eller kunden foretager en handling, der forlænger forældelsesfristen, fx anmeldelse af en skade, vil PFA Pension udskyde sletning af
                    personoplysningerne.</p>
                <p>PFA Pension sikrer, at personoplysninger, der er slettet fra systemet i drift, fjernes, hvis en backup genetableres.</p>
            </MiniBox>


            <Header as="h4">4. AUTOMATISKE INDIVIDUELLE AFGØRELSER</Header>
            <p>PFA Pension anvender automatiserede individuelle afgørelser, når du ønsker at oprette eller ændre din forsikringsordning via Letpension, og det indebærer en risikostigning for PFA Pension.
                Den generelle logik bag automatiseringen er, at systemet beregner, om du kan foretage den ønskede oprettelse eller risikostigning på din ordning. Beregningen sker automatisk.
                Hvis der er mulige udfald af behandlingen er et afslag, vil behandlingen overgå til en PFA-medarbejder, så automatiseringen ikke får negative konsekvenser for dig som kunde.</p>

            <Header as="h4">5. PLIGTMÆSSIG INFORMATION </Header>
            <p>PFA Pension har brug for personoplysninger om dig, når de fx opretter og administrerer din pensions- og forsikringsordning. Hvis du ikke giver
                personoplysningerne til PFA Pension, vil konsekvensen være, at PFA Pension ikke kan varetage formålene ovenfor, fx at de ikke kan oprette dig som kunde
                i PFA Pension eller ikke kan ændre din pensionsordning.</p>

            <Header as="h4">6. DINE RETTIGHEDER</Header>
            <p>Når PFA Pension behandler personoplysninger om dig, har du følgende rettigheder:</p>
            <ul>
                <li><u>Retten til indsigt</u><br />
                    <div>Du har ret til at få indsigt i, hvilke personoplysninger PFA Pension behandler om dig.</div>
                </li>
                <li><u>Ret til at gøre indsigelse</u><br />
                    <div>Du har ret til at modsætte dig behandlingen af dine personoplysninger og få behandlingen af dine personoplysninger begrænset. Særligt har du en
                        ubetinget ret til at modsætte dig behandling af dine personoplysninger til brug for direkte markedsføring og til at gøre indsigelse mod profilering
                        i det omfang, den vedrører direkte markedsføring.
                    </div>
                </li>
                <li><u>Retten til berigtigelse</u><br />
                    <div>Du har ret til at få berigtiget urigtige personoplysninger uden unødig forsinkelse, herunder har du under hensyn til formålene med behandlingen
                        ret til at få tilføjet eventuelt manglende personoplysninger.
                    </div>
                </li>
                <li><u>Retten til sletning</u><br />
                    <div>Du kan se, hvornår PFA Pension sletter dine personoplysninger under afsnit 3 – Opbevaring af dine personoplysninger. Du har i særlige tilfælde
                        ret til at få dine personoplysninger slettet i PFA Pension inden de angivne frister.
                    </div>
                </li>
                <li><u>Retten til begrænset behandling</u><br />
                    <div>Du har ret til at begrænse PFA Pensions behandling af dine personoplysninger i visse tilfælde, blandt andet når der foreligger tvivl om dine
                        personoplysningers rigtighed.
                    </div>
                </li>
                <li><u>Retten til dataportabilitet</u><br />
                    <div>Du har ret til at få personoplysninger, som du selv har afgivet til PFA Pension, udleveret til dig i et struktureret, almindeligt anvendt og
                        maskinlæsbart format. I samme tilfælde har du en ret til at få dine personoplysninger overdraget fra PFA Pension til en anden dataansvarlig, fx til
                        et andet pensions- og forsikringsselskab.
                    </div>
                </li>
                <li><u>Tilbagekaldelse af samtykke</u><br />
                    <div>Hvis PFA Pension behandler dine personoplysninger på baggrund af dit samtykke, har du til enhver tid ret til at tilbagekalde dit samtykke. En
                        tilbagekaldelse af samtykket indebærer, at PFA Pension ikke fremover må behandle dine personoplysninger til det formål, som du gav dit samtykke til.
                        Tilbagekaldelsen påvirker ikke den behandling af dine personoplysninger, som er foretaget forud for tilbagekaldelsen, fx hvis du har givet os
                        samtykke til at videregive oplysningerne. PFA Pension kan være berettiget til at behandle (fx opbevare) dine personoplysninger på et andet grundlag
                        end samtykke.
                    </div>
                    <div>Hvis du tilbagekalder dit samtykke, kan konsekvensen være, at PFA Pension i nogle tilfælde ikke vil kunne fortsætte kundeforholdet.</div>
                </li>
            </ul>
            <br />

            <p>Du kan gøre brug af dine rettigheder ved at ringe til din rådgiver i dit pengeinstitut eller PFA Pensions databeskyttelsesrådgiver.</p>

            <p>Der kan være betingelser eller begrænsninger til rettighederne ovenfor. Det er derfor ikke sikkert, at du fx har ret til dataportabilitet eller ret til at få
                personoplysninger slettet – dette afhænger af de konkrete omstændigheder i forbindelse med databehandlingen.</p>

            <Header as="h4">7. KONTAKTOPLYSNINGER PÅ PFA PENSIONS DATABESKYTTELSESRÅDGIVER (DPO)</Header>
            <p>Hvis du har spørgsmål til PFA Pensions beskyttelse og behandling af dine personoplysninger, er du velkommen til at kontakte PFA Pensions
                databeskyttelsesrådgiver.</p>

            <p>Du kan kontakte vores databeskyttelsesrådgiver på følgende måder:</p>

            <table style={{ color: 'black' }}>
                <tbody>
                    <tr>
                        <td>På e-mail:</td>
                        <td />
                        <td style={{ paddingLeft: '3em' }}> databeskyttelse@pfa.dk</td>
                    </tr>
                    <tr>
                        <td>På telefon:</td>
                        <td />
                        <td style={{ paddingLeft: '3em' }}> +45 70 20 75 15</td>
                    </tr>
                    <tr>
                        <td>Ved brev:</td>
                        <td />
                        <td style={{ paddingLeft: '3em' }}> PFA Pension, forsikringsaktieselskab</td>
                    </tr>
                    <tr>
                        <td />
                        <td />
                        <td style={{ paddingLeft: '3em' }}> Sundkrogsgade 4</td>
                    </tr>
                    <tr>
                        <td />
                        <td />
                        <td style={{ paddingLeft: '3em' }}> 2100 København Ø</td>
                    </tr>
                    <tr>
                        <td />
                        <td />
                        <td style={{ paddingLeft: '3em' }}> Att: Databeskyttelsesrådgiver</td>
                    </tr>
                </tbody>
            </table>

            <Header as="h4">8. KLAGE TIL DATATILSYNET</Header>
            <p>Du har til enhver tid ret til at indgive en klage til Datatilsynet over PFA Pensions behandling af dine personoplysninger. Du bør dog altid først tage
                kontakt til PFA Pension, hvis du mener, PFA Pension har behandlet dine personoplysninger i strid med den persondataretlige regulering. På den måde kan du
                få PFA Pensions forklaring af sagen. Du kan kontakte Datatilsynet pr. mail <a className="link" href="mailto:dt@datatilsynet.dk">dt@datatilsynet.dk</a> eller læse
                mere på <a className="link" href="https://www.datatilsynet.dk">www.datatilsynet.dk</a>.</p>

            <Divider hidden />
            <div><i>Sidst opdateret: 1. marts 2023</i></div>

        </Container>
    </div>
);

export default BehandlingAfPersonoplysninger;
