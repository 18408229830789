import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router";
import ResponsiveContainer from "./header/ResponsiveContainer";
import Main from "./main/Main";
import Forside from "./Forside";
import Footer from "./footer/Footer";
import NotFound from "./NotFound";
import ForKunder from "./forkunder/ForKunder";
import ScrollToTop from "./header/ScrollToTop";
import Produktet from "./produktet/Produktet";
import MoedeForberedelse from "./forkunder/MoedeForberedelse";
import TjekDinPension from "./forkunder/TjekDinPension";
import Helbredsoplysninger from "./forkunder/Helbredsplysninger";
import Begunstigelse from "./forkunder/Begunstigelse";
import BehandlingAfPersonoplysninger from "./forkunder/BehandlingAfPersonoplysninger";
import Skatteregler from "./forkunder/Skatteregler";
import LetsikringVedSygdom from "./produktet/LetsikringVedSygdom";
import LetsikringVedKritiskSygdom from "./produktet/LetsikringVedKritiskSygdom";
import LetsikringAfIndtaegtVedPension from "./produktet/LetsikringAfIndtaegtVedPension";
import LetsikringAfBarnVedDoed from "./produktet/LetsikringAfBarnVedDoed";
import LetsikringVedDoed from "./produktet/LetsikringVedDoed";

import { Provider } from "react-redux";
import Investering from "./produktet/Investering";
import BlivKunde from "./blivkunde/BlivKunde";
import OmLetpension from "./omletpension/OmLetpension";
import Medarbejdere from "./omletpension/Medarbejdere";
import Bestyrelse from "./omletpension/Bestyrelse";
import Jobopslag from "./omletpension/Jobopslag";
import PrivatlivOgCookies from "./PrivatlivOgCookies";
import Faq from "./Faq";
import OmkostningerStandard from "./produktet/OmkostningerStandard";
import OmkostningerUdvidet from "./produktet/OmkostningerUdvidet";
import appRoutes from "../routes/appRoutes";
import Direktion from "./omletpension/Direktion";

import LetsikringAfHelbred from "./produktet/LetsikringAfHelbred";
import BrugDinForsikring from "./BrugDinForsikring";
import Klima from "./Klima";
import LetsikringTilSelvstaendige from "./produktet/LetsikringTilSelvstaendige";
import Selvstaendige from "./Selvstaendige";
import Senior from "./Senior";
import BegunstigelseLanding from "./BegunstigelseLanding";
import Pensionsdagen from "./Pensionsdagen";
import Pensionsdagen2023 from "./Pensionsdagen2023";
import Pensionsdagen2024 from "./Pensionsdagen2024";

const App = (props) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <ResponsiveContainer>
            <Main>
              <Switch>
                <Route exact path={appRoutes.ROOT} component={Forside} />
                <Route
                  exact
                  path={appRoutes.FOR_KUNDER}
                  component={ForKunder}
                />
                <Route
                  path={appRoutes.TJEK_DIN_PENSION}
                  component={TjekDinPension}
                />
                <Route
                  path={appRoutes.BRUG_DIN_FORSIKRING}
                  component={BrugDinForsikring}
                />
                <Route
                  path={appRoutes.MOEDEFORBEREDELSE}
                  component={MoedeForberedelse}
                />
                <Route
                  path={appRoutes.HELBREDSOPLYSNINGER}
                  component={Helbredsoplysninger}
                />
                <Route
                  path={appRoutes.BEGUNSTIGELSE}
                  component={Begunstigelse}
                />
                <Route
                  path={appRoutes.BEHANDLING_AF_PERSONOPLYSNINGER}
                  component={BehandlingAfPersonoplysninger}
                />
                {/*Dette link benyttes fra aftalebeviset. Må ikke fjernes*/}
                <Route
                  path="/personoplysninger"
                  component={BehandlingAfPersonoplysninger}
                />
                {/*Dette link er ved en fejl udsendt i et brev. Må ikke fjernes foreløbig*/}
                <Route
                  path="/forkunderbehandlingafpersonoplysninger"
                  component={BehandlingAfPersonoplysninger}
                />
                <Route path={appRoutes.SKATTEREGLER} component={Skatteregler} />
                <Route exact path={appRoutes.PRODUKTET} component={Produktet} />
                <Route
                  path={appRoutes.LETSIKRING_VED_SYGDOM}
                  component={LetsikringVedSygdom}
                />
                <Route
                  path={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}
                  component={LetsikringVedKritiskSygdom}
                />
                <Route
                  path={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}
                  component={LetsikringAfIndtaegtVedPension}
                />
                <Route
                  path={appRoutes.LETSIKRING_AF_BARN_VED_DOED}
                  component={LetsikringAfBarnVedDoed}
                />
                <Route
                  path={appRoutes.LETSIKRING_AF_HELBRED}
                  component={LetsikringAfHelbred}
                />
                <Route
                  path={appRoutes.LETSIKRING_VED_DOED}
                  component={LetsikringVedDoed}
                />
                <Route
                  path={appRoutes.LETSIKRING_TIL_SELVSTAENDIGE}
                  component={LetsikringTilSelvstaendige}
                />
                <Route
                  path={appRoutes.OMKOSTNINGER}
                  component={OmkostningerStandard}
                />
                <Route
                  path={appRoutes.OMKOSTNINGER_UDVIDET}
                  component={OmkostningerUdvidet}
                />
                <Route exact path={appRoutes.INVESTERING}>
                  <Redirect to={appRoutes.INVESTERING_AFKAST} />
                </Route>
                <Route
                  path={appRoutes.INVESTERING_AFKAST}
                  component={Investering}
                />
                <Route
                  path={appRoutes.INVESTERING_PROFILER}
                  component={Investering}
                />
                <Route
                  path={appRoutes.INVESTERING_FONDE}
                  component={Investering}
                />
                <Route
                  path={appRoutes.INVESTERING_KUNDEKAPITAL}
                  component={Investering}
                />
                <Route
                  path={appRoutes.INVESTERING_KLIMA}
                  component={Investering}
                />
                <Route
                  path={appRoutes.INVESTERING_BAEREDYGTIGHED}
                  component={Investering}
                />
                <Route
                  exact
                  path={appRoutes.BLIV_KUNDE}
                  component={BlivKunde}
                />
                <Route
                  exact
                  path={appRoutes.OM_LETPENSION}
                  component={OmLetpension}
                />
                <Route path={appRoutes.MEDARBEJDERE} component={Medarbejdere} />
                <Route path={appRoutes.DIREKTION} component={Direktion} />
                <Route path={appRoutes.BESTYRELSE} component={Bestyrelse} />
                <Route path={appRoutes.JOBOPSLAG} component={Jobopslag} />
                {/*<Route path={appRoutes.NYHEDER} component={Nyheder}/>*/}
                <Route
                  exact
                  path={appRoutes.PRIVATLIV_OG_COOKIES}
                  component={PrivatlivOgCookies}
                />
                <Route exact path={appRoutes.KONTAKT}>
                  <Redirect to={appRoutes.FAQ} />
                </Route>
                <Route exact path={appRoutes.FAQ} component={Faq} />
                <Route exact path={appRoutes.KLIMA} component={Klima} />
                <Route
                  exact
                  path={appRoutes.SELVSTAENDIGE}
                  component={Selvstaendige}
                />
                <Route exact path={appRoutes.SENIOR} component={Senior} />
                <Route
                  exact
                  path={appRoutes.BEGUNSTIGELSELANDING}
                  component={BegunstigelseLanding}
                />
                <Route
                  exact
                  path={appRoutes.PENSIONSDAGEN}
                  component={Pensionsdagen}
                />
                <Route
                  exact
                  path={appRoutes.PENSIONSDAGEN2023}
                  component={Pensionsdagen2023}
                />
                <Route
                  exact
                  path={appRoutes.PENSIONSDAGEN2024}
                  component={Pensionsdagen2024}
                />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <Footer />
          </ResponsiveContainer>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default App;
