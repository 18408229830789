import React from "react";
import { Container, Divider, Grid, Segment } from "semantic-ui-react";
import IkonTrilling1 from "../images/Trilling1.png";
import IkonTrilling2 from "../images/Trilling2.png";
import IkonTrilling3 from "../images/Trilling3.png";
import "./Selvstaendige.css";
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/pensionsdagen2024.jpg";
import AutoAccordion from "./common/AutoAccordion";

const Pensionsdagen = (props) => (
  <div style={{ backgroundColor: "white" }}>
    <HeaderImage imageSrc={Forsidebillede} />
    <Container text>
      <Segment basic>
        <p>Oktober 2024</p>
        <h2 className="trompet">DIT FREMTIDSJEG VIL TAKKE DIG</h2>
        <h2 className="ui header" style={{ paddingTop: 0 }}>
          Du får en ekstra time, brug den på din pension
        </h2>
      </Segment>
    </Container>

    <Container text>
      <Segment basic>
        <p className="manchet">
          I en travl hverdag kan det være svært at finde tid til at få overblik
          over sin pensionsopsparing – selv med de bedste intentioner.
        </p>

        <p>
          Søndag den 27. oktober 2024, når sommertiden slutter, får vi alle en
          ekstra time. Det er oplagt at bruge den på at få overblik over sin
          pension.
        </p>

        <h3>Få hjælp af din rådgiver i dit pengeinstitut</h3>
        <p>
          Med MitID i hånden og med få klik på computeren kan du på{" "}
          <a href="https://pensionsinfo.dk" target="_blank" rel="noreferrer">
            pensionsinfo.dk
          </a>{" "}
          danne en rapport, som du kan sende til din rådgiver i dit
          pengeinstitut. Det tager ikke mere end et par minutter. Så kan du
          efterfølgende sammen med din rådgiver kigge på, om din opsparing
          rækker til dine ønsker for tilværelsen som pensionist, og om du har de
          rigtige forsikringsdækninger.
        </p>
        <p>
          TIP! Hvis du logger på PensionsInfo fra dit pengeinstitut, kan du
          sende rapporten direkte til dem.
        </p>

        <h3>Værdien af at begynde din opsparing tidligt</h3>
        <p>
          Jo tidligere du begynder at spare op, jo mere vil afkastet arbejde for
          dig. Selv små beløb vokser sig store over tid, og det kan være med til
          at sikre, at der er råd til alt det, du drømmer om senere i livet.
        </p>
        <p>
          Der er stor forskel på at begynde tidligt og sent. Og det skyldes
          rentes rente-effekten, som Einstein omtalte som “den stærkeste kraft i
          universet” og “verdens ottende vidunder”.
        </p>
        <p>
          For at illustrere rentes rente-effekten har vi lavet dette
          regneeksempel på trillinger, som hver sparer 540.000 kr. op. Hvor
          meget har de som 65-årig? Forskellen er markant.{" "}
        </p>

        <Divider hidden />

        <Grid centered stackable columns={2}>
          <Grid.Row>
            <Grid.Column width={3}>
              <div
                className="fordele-ikon"
                style={{ backgroundImage: `url(${IkonTrilling1})` }}
              ></div>
            </Grid.Column>
            <Grid.Column width={13}>
              <p>
                Trilling 1 sparer <b>1.000 kr./mdr. i 45 år</b> fra 20 år til 65
                år
              </p>
              <p>
                Det giver en samlet opsparing som 65-årig: <b>1.766.000 kr.</b>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <div
                className="fordele-ikon"
                style={{ backgroundImage: `url(${IkonTrilling2})` }}
              ></div>
            </Grid.Column>
            <Grid.Column width={13}>
              <p>
                Trilling 2 sparer <b>2.500 kr./mdr. i 18 år</b> fra 47 år til 65
                år
              </p>
              <p>
                Det giver en samlet opsparing som 65-årig: <b>858.000 kr.</b>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <div
                className="fordele-ikon"
                style={{ backgroundImage: `url(${IkonTrilling3})` }}
              ></div>
            </Grid.Column>
            <Grid.Column width={13}>
              <p>
                Trilling 3 sparer <b>2.500 kr./mdr. i 18 år</b> fra 20 år til 38
                år
              </p>
              <p>
                Det giver en samlet opsparing som 65-årig: <b>2.823.000 kr.</b>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />

        <p style={{ fontSize: 15 }}>
          Forudsætninger: Ingen pristalsregulering af indbetalinger. Investeret
          med 75 % aktier og 25 % obligationer. Forventet afkast jf.
          2024-samfundsforventninger efter investeringsomkostninger. Ingen
          øvrige omkostninger medtaget. PAL-skat fratrukket. Ingen automatisk
          nedtrapning af risiko. Saldo er i fremtidskroner. Kilde PFA.
        </p>
        <Divider hidden />
        <h3>Tips til at få overblik</h3>
        <p>
          Få her inspiration til, hvilke spørgsmål det kan være relevant at
          svare på for at få et overblik, der kommer hele vejen rundt. Og husk,
          du kan få hjælp i dit pengeinstitut.
        </p>
        <AutoAccordion activeIndex={-1}>
          <AutoAccordion.Item title="Indbetaler du nok?">
            Ønsker du at kunne gå ned i tid, inden du skal på pension? Ønsker du
            at gå tidligere på pension? Ønsker du at sikre din levestandard, når
            pensionsalderen bliver en realitet? Hvad end dine planer og drømme
            er, giver det tryghed at vide, om økonomien rækker. Jo tidligere du
            får overblik, jo flere handlemuligheder har du.
          </AutoAccordion.Item>
          <AutoAccordion.Item title="Hvordan er dit sikkerhedsnet?">
            Har du overblik over dine forsikringer, og ved du, hvornår og
            hvordan de dækker dig? Pension er også forsikringer, der dækker dig,
            hvis du bliver syg eller kommer til skade. Disse forsikringer giver
            en god tryghed, og med et økonomisk sikkerhedsnet i ryggen kan du
            bruge energien på det, der er vigtigst i hverdagen.
          </AutoAccordion.Item>
          <AutoAccordion.Item title="Kender du fordelene af at have en sundhedsforsikring?">
            Hvis du ikke allerede har en sundhedsforsikring, kan du med fordel
            oprette en. Med den har du mulighed for at få hjælp til behandling
            eller udredning, hvis du får gener, bliver syg eller får en skade .
            Du kan bl.a. få adgang til behandlinger hos fysioterapeut, osteopat,
            kiropraktor, psykolog og psykiater. Du kan også få hjælp, hvis du
            har et barn, som mistrives.
          </AutoAccordion.Item>
          <AutoAccordion.Item title="Har du den rette investeringsprofil?">
            Den rette investeringsprofil er den, der giver ro i maven. Der er
            altid en risiko ved at investere, fordi markederne går op og ned, og
            derfor handler det om at mærke efter, hvilken risiko du er villig
            til at løbe i forhold til det afkast, du har mulighed for at få. Det
            er dog værd at bemærke, at pensionsopsparing er langsigtet, så vær
            tro mod din investeringsstrategi fremfor at forsøge at time de ofte
            uforudsigelige udsving på markederne.
          </AutoAccordion.Item>
          <AutoAccordion.Item title="Hvem skal have opsparingen, hvis du dør før tid?">
            <p>
              Har du taget stilling til, hvem der skal have udbetalingerne fra
              din pensionsopsparing, hvis du dør før tid? Hvert år dør ca. 9.000
              danskere, inden de bliver pensioneret, og dermed får de ikke selv
              glæde af deres pensionsopsparing. Omkring 9 milliarder kr. bliver
              hvert år udbetalt til de efterladte. Derfor er det en god ide at
              tjekke, om det er de rigtige personer, der står til at få
              pensionen.
            </p>
            <p>
              På{" "}
              <a
                href="https://pensionsinfo.dk"
                target="_blank"
                rel="noreferrer"
              >
                pensionsinfo.dk
              </a>{" "}
              kan du se, hvem der er begunstiget på dine pensioner.
            </p>
          </AutoAccordion.Item>
          <AutoAccordion.Item title="Hvornår er det vigtigt, at du tjekker din pension?">
            <p>
              Vi anbefaler, at du som minimum tjekker din pension årligt for at
              sikre, at du er på rette vej mod dine opsparingsmål.
            </p>
            <p>Tjek også din pension, når der sker store ændringer:</p>
            <ul>
              <li>
                Jobskifte: Når du skifter job, er det oplagt, at du gennemgå din
                pensionsordning.
              </li>
              <li>
                Store livsbegivenheder: Tjek din pension ved fx. ægteskab,
                skilsmisse eller fødsel.
              </li>
              <li>
                Ændringer i din indkomst: Juster indbetalingen ved stigende
                eller faldende indkomst.
              </li>
              <li>
                Køb/salg af bolig: Overvej, hvordan boligkøb/salg påvirker din
                økonomi og pensionsopsparing.
              </li>
              <li>
                Nær pensionsalderen: Planlæg udbetalinger i god tid, inden du
                trækker dig tilbage. Rækkefølgen af, hvordan dine pensioner
                bliver udbetalt, kan have stor indflydelse på din økonomiske
                situation i pensionisttilværelsen.
              </li>
            </ul>
          </AutoAccordion.Item>
        </AutoAccordion>
      </Segment>
    </Container>
  </div>
);

export default Pensionsdagen;
